import React from 'react'
import { Link } from 'gatsby'

const NotFoundPage = () => {
  return (
    <>
      <h1>NOT FOUND</h1>
      <p>Redirecting</p>

      <Link to="/">Go To Home -></Link>
    </>
  )
}

export default NotFoundPage
